import { IInstallation } from "../installation.interface";

export interface OCPIGetLocation {
  request: OCPILocationRequest,
  data: IInstallation,
}

export interface OCPILocationRequest {
  country_code: string,
  party_id: string,
  location_id: string,
  evse_uid?: string,
  connector_id?: string,
}

export interface OCPIReqParams {
  cpoId: string,
  dateFrom?: string,
  dateTo?: string,
  offset?: number,
  limit?: number,
}

export interface OCPIUnlockConectorParams {
  cpoId?: string,
  locationId?: string,
  evseUid?: string,
  connectorId?: string,
}

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never

export type ValueOf<T> = T[keyof T];


export const WHITE_LIST = {
  /**
   * Token always has to be whitelisted, realtime authorization is not possible/allowed.
   * CPO shall always allow any use of this Token.
   */
  ALWAYS: 'ALWAYS',

  /**
   * It is allowed to whitelist the token, realtime authorization is also allowed.
   * The CPO may choose which version of authorization to use.
   */
  ALLOWED: 'ALLOWED',

  /**
   * In normal situations realtime authorization shall be used.
   * But when the CPO cannot get a response from the eMSP (communication between CPO and eMSP is offline)
   * the CPO shall allow this Token to be used.
   */
  ALLOWD_OFFLINE: 'ALLOWED_OFFLINE',

  /**
   * Whitelisting is forbidden, only realtime authorization is allowed.
   * CPO shall always send a realtime authorization for any use of this Token to the eMSP
   */
  NEVER: 'NEVER'
} as const;
export type WhitelistKeys = keyof typeof WHITE_LIST;
export type WhitelistValues = ValueOf<typeof WHITE_LIST>;

export const PROFILE = {
  /**
   * Driver wants to use the cheapest charging profile possible.
   */
  CHEAP: 'CHEAP',

  /**
   * Driver wants his EV charged as quickly as possible and is willing to pay a premium for this if needed
   */
  FAST: 'FAST',

  /**
   * Driver wants his EV charged with as much regenerative (green) energy as possible.
   */
  GREEN: 'GREEN',

  /**
   * Driver does not have special preferences
   */
  REGULAR: 'REGULAR',
} as const;
export type ProfileKeys = keyof typeof PROFILE;
export type ProfileValues = ValueOf<typeof PROFILE>;

export interface IEnergyContract {
  /**
   * Name of the energy supplier for this token;
   */
  supplier_name: string;

  /**
   * Optional Contract ID at the energy supplier, that belongs to the owner of this token.
   */
  contract_id?: string;
}

export interface IPaginationParams {
  dateFrom?: Date,
  dateTo?: Date,
  offset?: number,
  limit?: number,
}

export enum OCPIVersion {
  V2_1_1 = '2.1.1',
  V2_2 = '2.2',
}