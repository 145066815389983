import { IEnergyContract, ProfileValues, WhitelistValues } from "./commands.interface";
import { TokenValues } from "./enums/toknType.enum";
import { OCPIDisplayText } from "./ocpi-interfaces";
/**
 * THIS IS NOT THE SAME AS THE OCPI INTERFACE, IS INSPIRED TO IT!
 */
export interface IToken {
  /**
   * Id on our database
  */
  id: string;
  /**
  * When has it been created on our Database
  */
  createdAt: Date;
  /**
   * When it has been updated on our Database;
   */
  updatedAt: Date;
  /**
   * Case insensitive ISO-3166 alpha-2 country code of the MSP that 'owns' this Token.
   */
  countryCode?: string;
  /**
   * Case insensitive CPO ID of the MSP that 'owns' this Token (following the ISO-15118 standard)
   */
  partyId?: string;
  /**
   * Case insensitive Unique ID by which this Token can be identified.
   * This is the field used by CPO system (RFID reader on the ChargePoint) to identify this token.
   * Currently, in most cases: type=RFID, this is the RFID hidden ID as read by the RFID reader, but that is not a requirement.
   * if this is a APP_USER or AD_HOC_USER Token, it will be a uniquely, by the eMSP, generated ID.
   * this field is named uid instead of id to prevent confusion with: contract_id;
   */
  uid: string;
  userId: string;
  /**
   * Type of the token
   */
  type: TokenValues;
  /**
   * Uniquely identifies the EV Driver contract token within the eMSP's platform (and suboperator platforms).
   * Recommended to follow the specification for eMA ID from "eMI3 standard version v1.0" http://emi3group.com/documents-links/ "Part2: business objects"
   */
  contractId: string;
  /**
   * Optional visual readable number/identification as printed on the Token (RFID card)
   * might be equal to the contract_id
   */
  visualNumber?: string;
  /**
   * Issueing company, most of the times the name of the company printed on the token (RFID card)
   * not necessarily the eMSP
   */
  issuer?: string;
  /**
   * Case insensitive - This ID groups a couple of tokens. This can be used to make two or more tokens work as one,
   * so that a session can be started with one token and stopped with another,
   * handy whena  card and key-fob are given to the EV-driver.
   * Beware that OCPP 1.5/1.6 only support group_ids (it is called parentId in OCPP 1.5/1.6) with maximum length of 20.
   */
  groupId?: string;
  valid: boolean;
  whitelist: WhitelistValues;
  /**
   * Optional laungage Code ISO 639-1. Indicates the Token owner's preferred interface language.
   * If the language is not provided or not supported then the CPO is free to choose its own language.
   */
  language?: string;
  /**
   * Optional default Charing Preference.
   * When this is provided, and a charging session is started on a Charge Point
   * that support Preference base Smart Charging and support this ProfileType, the Charge POint can start using this Profile Type
   * without this having to be set via Set Charging Preferences.
   */
  defaultProfileType?: ProfileValues;
  /**
   * Optional - When the Charge point supports using your own energy supplier/contract at a Charge Point,
   * information about the energy supplier/contract is needed so the CPO knows
   * which energy supplier to user.
   * In a lot of countries it is currently not allowed/possible to use a drivers own energy supplier/contract at a Charge Point.
   */
  energyContract?: IEnergyContract;
}
export interface OCPILocationReferenceV2_1_1 {
  /**
  * Case insensitive unique ID of the location.
  * This ID is used by the CPO to identify the location.
  * */
  location_id: string;
  evse_uids: string[];
  /**
   * Non è presente nella versione 2.2 
   * Attenzione!!
   */
  connector_ids: string[];
}


export interface OCPIAuthorizationInfoV2_1_1 {
  allowed: OCPIAllowedType;
  location?: OCPILocationReferenceV2_1_1;
  info?: OCPIDisplayText;
}

export enum OCPIAllowedType {
  ALLOWED = 'ALLOWED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  NO_CREDIT = 'NO_CREDIT',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export interface OCPITokenV2_1_1 {
  uid: string;
  type: TokenTypeV2_1_1;
  auth_id: string;
  visual_number?: string;
  issuer: string;
  valid: boolean;
  whitelist: WhitelistType;
  language?: string;
  last_updated: Date;
}

export enum TokenTypeV2_1_1 {
  OTHER = 'OTHER',
  RFID = 'RFID',
}

export enum WhitelistType { // Se non ci sta la versione vuol dire che è comune!
  ALWAYS = 'ALWAYS',
  ALLOWED = 'ALLOWED',
  ALLOWED_OFFLINE = 'ALLOWED_OFFLINE',
  NEVER = 'NEVER',
}
///// TOKEN VERSION 2.2
export interface OCPILocationReferenceV2_2 {
  /**
   * Case insensitive unique ID of the location.
   * This ID is used by the CPO to identify the location.
   * */
  location_id: string;
  evse_uids: string[];
}

export interface OCPIAuthorizationInfoV2_2 {
  allowed: OCPIAllowedType;
  token: OCPITokenV2_2;
  location?: OCPILocationReferenceV2_2;
  authorization_reference?: string;
  info: OCPIDisplayText
}

export interface OCPITokenV2_2 {
  country_code: string;
  party_id: string;
  uid: string;
  type: TokenTypeV2_2;
  contract_id: string;
  visual_number?: string;
  issuer: string;
  group_id?: string;
  valid: boolean;
  whitelist: WhitelistType;
  language?: string;
  default_profile_type?: ProfileTypeV2_2;
  energy_contract?: IEnergyContract;
  last_updated: Date;
}

export enum TokenTypeV2_2 {
  APP_USER = 'APP_USER',
  AD_HOC_USER = 'AD_HOC_USER',
  RFID = 'RFID',
  OTHER = 'OTHER',
}

export enum ProfileTypeV2_2 {
  CHEAP = 'CHEAP',
  FAST = 'FAST',
  GREEN = 'GREEN',
  REGULAR = 'REGULAR',
}
