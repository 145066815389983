export type ValueOf<T> = T[keyof T];

export const AuthType = Object.freeze({
  /**
   * One time use Token ID generated by a server (or App)
   * The eMSP uses this to bind a Sessiont o a customer, probably an app user.
   */
  AD_HOC_USER: 0,

  /**
   * Token ID generated by a server (or App) to identify a user of an App.
   * The same user uses the same Token for every Session
   */
  APP_USER: 1,
  OTHER: 2,
  RFID: 3,
  ADMIN: 4,
  // Significa che un eMSP ha autorizzato questa ricarica!
  OCPI: 5,
});

export type AuthTypeValues = ValueOf<typeof AuthType>;
export type AuthTypeKeys = keyof typeof AuthType;