export const _ = {
  VERSION: '1.315',
  MSxMINUTE: 60000,
  // API_URL:'http://localhost:3000',
  // AUTH_URL: '/auth',
  CLIENT_KEY: '_YF_CLIENT',
  AUTHSTATE_KEY: '_YF_AUTHSTATE',
  GEOLOCATION_KEY: '_YF_GEOLOCATION',
  USERS_SEARCH_CRITERIA: '_YF_USERS_SEARCH_SETTINGS',
  INSTALLATION_SEARCH_CRITERIA: '_YF_INSTALLATION_SEARCH_SETTINGS',
  STATION_SEARCH_CRITERIA: '_YF_STATION_SEARCH_SETTINGS',
  PODS_SEARCH_CRITERIA: '_YF_PODS_SEARCH_SETTINGS',
  GROUPS_SEARCH_CRITERIA: '_YF_GROUPS_SEARCH_SETTINGS',
  GATEWAYS_SEARCH_CRITERIA: '_YF_GATEWAYS_SEARCH_SETTINGS',
  TARIFFS_SEARCH_CRITERIA: '_YF_TARIFFS_SEARCH_SETTINGS',
  CDR_SEARCH_CRITERIA: '_YF_CDR_SEARCH_SETTINGS',
  SESSION_SEARCH_CRITERIA: '_YF_SESSION_SEARCH_SETTINGS',
  COST_REVENUE_SPLIT_SEARCH_CRITERIA: '_YF_COST_REVENUE_SPLIT_SEARCH_SETTINGS',
  CHAPTERS_SEARCH_CRITERIA: '_YF_CHAPTERS_SEARCH_SETTINGS',
  SECTIONS_SEARCH_CRITERIA: '_YF_SECTIONS_SEARCH_SETTINGS',
  PAYMENT_SEARCH_CRITERIA: '_YF_PAYMENT_SEARCH_SETTINGS',
  LOG_SEARCH_CRITERIA: '_YF_LOG_SEARCH_SETTINGS',
  MAP_SELECTED_INSTALLATION: '_YF_MAP_SELECTED_INSTALLATION',
  MAP_CLUSTER_IDS: '_YF_MAP_CLUSTER_IDS',
  INVOICE_SEARCH_CRITERIA: '_YF_INVOICE_SEARCH_SETTINGS',
  GESTORE_SEARCH_CRITERIA: '_YF_GESTORE_SEARCH_SETTINGS',
  QUERY_SEARCH_CRITERIA: '_YF_QUERY_SEARCH_SETTINGS',
  EXPORT_SEARCH_CRITERIA: '_YF_EXPORT_SEARCH_SETTINGS',
  LANG_KEY: '_YF_LANG',
};
